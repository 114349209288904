import { Button } from "@nextui-org/react";
import { useState } from "react";
import { FiCheckCircle } from "react-icons/fi";

export default function CookiesInfoBar(props: any) {
    const [acceptedCookies, setAcceptedCookies] = useState(localStorage.getItem("acceptedCookies"));

    if (acceptedCookies) {
        return <></>
    }

    return (
        <div className="fixed text-xs justify-center items-center p-2 bottom-0 z-[100] flex flex-row gap-2 bg-content1 w-full">
            <div>We use cookies to make sure the site works. We do not use advertising or tracking cookies.</div>
            <Button 
                onPress={() => {localStorage.setItem("acceptedCookies", "yes"); setAcceptedCookies("yes");}}
                size="sm" 
                className="button-action-tiny" 
                startContent={<FiCheckCircle size={14} />}>OK</Button>
        </div>
    )
}