// import { createContext, useEffect, useState } from "react";
import { BackendResponse, backendSend } from "@/hooks/useBackend";
import { DisplayToast } from "@/backend/toasts";
import { DisplayNotification, AddNotifications } from "@/backend/notifications";

// interface SessionContext {
//     data: any;
//     isLoggedIn: boolean;
//     mutate: Function;
// }

// export const UserSessionContext = createContext<SessionContext | undefined>(undefined);

// export function useSessionContext() {
//     const [ isLoggedIn, setLoggedIn ] = useState<boolean | null>(null);

//     const { response, isLoading, mutate } = useBackend({ endpoint: "/user", swrOptions: { refreshInterval: 10000, refreshWhenHidden: true } });

//     useEffect(() => {
//         if (response) {
//             // console.log("hello from use session context");
//             const result = response.status === 200 ? true : false;
    
//             if (result !== isLoggedIn) {
//                 // console.log("not equal!");
//                 setLoggedIn(result);
//             }
//         }
//     }, [response]);

//     return { isLoggedIn };
// }

// interface wtf {
//     loggedIn: boolean | null;
// }
// export const sessionData: wtf = {
//     loggedIn: null
// }

// export function SessionChecker(props: any) {
//     // const [ isLoggedIn, setLoggedIn ] = useState<boolean | null>(null);

//     const [ response, setResponse ] = useState<BackendResponse | null>(null);

//     let intervalID: any;

//     useEffect(() => {
//         intervalID = setInterval(() => {
//             SessionCheck(setResponse);
//         }, 5000);
        
//         return () => clearInterval(intervalID);
//     }, []);

//     // const { response, isLoading, mutate } = useBackend({ endpoint: "/user", swrOptions: { refreshInterval: 1000, refreshWhenHidden: true } });

//     if (response) {
//         const result = response.status === 200 ? true : false;
        
//         if (result !== props.data.loggedIn) {
//             console.log("not equal!");
//             props.setter({ loggedIn: result });
//         };
//     };

//     return <></>
// };

// export function SessionCheck(setter: any) {
//     backendSend({ endpoint: "/user", method: "GET", responseCallback: setter })
// }

let lastValue: boolean | null = null;

export const sessionInfo: any = { setter: null }

export function RefreshSession(setter: Function, loop: boolean = true) {
    // const { response, isLoading, mutate } = useBackend({ endpoint: "/user", swrOptions: { refreshInterval: 10000, refreshWhenHidden: true } });
    sessionInfo.setter = setter;

    backendSend({
        endpoint: "/session",
        method: "GET",
        responseCallback: ProcessResponse,
        callbackParams: { setter: setter }
    });

    if (loop) {
        setTimeout(RefreshSession, 5000, setter);
    }
}

// function ProcessNotifications(data: any) {
//     data.map(function(item: any) {

//     });
// }

function ProcessResponse(response: BackendResponse, params: any) {
    // console.log("Processing response");
    const result = response.status === 200 ? true : false;

    SetUserData(response);
        
    if (result !== lastValue) {
        // console.log("not equal!");
        if (result) {
            // console.log("Enable login loop!");
            setTimeout(RefreshSession, 5000, params.setter, true);
        };

        lastValue = result;
        params.setter(result);
    };
}

function SetUserData(response: BackendResponse) {
    // console.log(response);

    if (response.status === 200) {
        sessionStorage.setItem("userData", JSON.stringify(response.data));

        // console.log(response.data.notifications);
        if (!response.data.notifications) {
            return;
        }

        if (Array.isArray(response.data.notifications) && response.data.notifications.length > 0) {
            AddNotifications(response.data.notifications)
        }
    } else {
        sessionStorage.removeItem("userData");
    }
}

export function GetUserData() {
    const userData = sessionStorage.getItem("userData");

    if (userData === null) {
        return null;
    }

    try {
        return JSON.parse(userData);
    } catch (error) {
        console.error("userData failed JSON parse");
        return null;
    }
}