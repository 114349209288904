import {Button, Image, Popover, PopoverContent, PopoverTrigger, User} from "@nextui-org/react";
import { useRouter } from 'next/router';
import {Navbar, NavbarBrand, NavbarContent, NavbarMenuToggle, NavbarMenu, NavbarItem, Link} from "@nextui-org/react";
import { ThemeSwitch } from '@/components/theme-switch';

import { FiActivity, FiBarChart2, FiBox, FiFolder, FiHome, FiInfo, FiMessageSquare, FiSettings, FiSlash, FiTool, FiUsers, FiArrowLeft, FiLogOut, FiPlayCircle, FiCreditCard, FiClipboard, FiCode } from "react-icons/fi";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { GetUserData } from "@/hooks/SessionContext";
import useSafePush from "@/hooks/useSafePush";
import { NotificationsComponent } from "../Notifications";
import { notificationStorage, Notification } from "@/backend/notifications";

export interface SidebarMenuEntry {
    icon?: JSX.Element;
    label: string;
    href?: string;
}

export interface SidebarProps {
    entries: SidebarMenuEntry[];
    activeHref?: string;
    pageTitle: string;
}

export interface PresetSidebarProps {
    activeHref?: string;
    pageTitle: string;
}


function GetRoutesArmaReforger(query: any) {
    const base = `/armareforger/${query.serverid}`;

    return [
        { icon: <FiArrowLeft />, label: "Back to Services", href: "/services" },
        { label: "Arma Reforger" },
        { icon: <FiActivity />, label: "Overview", href: base+"/overview" },
        { icon: <FiCode />, label: "RCON", href: base+"/rcon" },
        { icon: <FiBarChart2 />, label: "Stats", href: base+"/stats" },
        { label: "Configuration" },
        { icon: <FiSettings />, label: "Server Settings", href: base+"/config" },
        { icon: <FiTool />, label: "System Settings", href: base+"/system" },
        { icon: <FiCode />, label: "Integrations", href: base+"/integrations" },
        { label: "Tools" },
        { icon: <FiBox />, label: "Mod Manager", href: base+"/mods" },
        { icon: <FiFolder />, label: "File Manager", href: base+"/files" },
        { icon: <FiPlayCircle />, label: "Workflows", href: base+"/workflows" },
        { icon: <FiClipboard />, label: "Reports", href: base+"/reports"},
        { icon: <FiTool />, label: "Toolkit", href: base+"/toolkit" },
        { label: "Server Admin Tools" },
        { icon: <FiActivity />, label: "Status", href: base+"/serveradmintools" },
        { icon: <FiSettings />, label: "Settings", href: base+"/serveradmintools-settings" },
        { icon: <FiSlash />, label: "Ban Manager", href: base+"/serveradmintools-bans" },
    ]
}

function GetRoutesDefault() {
    // const userData = GetUserData();

    return [
        { label: "Services" },
        { icon: <FiActivity />, label: "Service Status", href: "/status" },
        { icon: <FiInfo />, label: "News", href: "/news" },
        { icon: <FiHome />, label: "Service List", href: "/services" },
        { label: "System" },
        { icon: <FiUsers />, label: "Users", href: "/users" },
        { icon: <FiCreditCard />, label: "Billing", href: "/billing" },
        { icon: <FiSettings />, label: "Account Settings", href: "/account" },
    ];

    // if (userData?.type === "password") {
    //     menuItems.push({ icon: <FiUsers />, label: "Users", href: "/users" })
    //     menuItems.push({ icon: <FiCreditCard />, label: "Billing", href: "/billing" })
    // }

    // return menuItems;
}

interface RouteGenerators {
    [key: string]: Function;
}
const routeGenerators: RouteGenerators = {
    "armareforger": GetRoutesArmaReforger,
    "default": GetRoutesDefault
}

function GetMenuItems(query: any) {
    const needles = window.location.pathname.split('/');

    if (needles.length < 2) {
        return routeGenerators["default"]();
    }

    if (needles[1] in routeGenerators) {
        return routeGenerators[needles[1]](query);
    }

    return routeGenerators["default"]();
}

function ProfileDropdown(props: any) {
    const { safePush } = useSafePush();
    const userData = GetUserData();

    if (userData === null) {
        return <></>
    }

    // {{ src: userData.type === "password" ? "/icon_avatar.png" : userData.avatar }}

    if (props.type === "mobile") {
        return (
            <div className="pt-4 pb-4">
                <Button 
                    startContent={<Image alt="avatar" src={userData.type === "password" ? "/icon_avatar.png" : userData.avatar} />} 
                    className="sidebar-profile mb-2 mt-4">{userData.name}</Button>
                <Button variant="flat" disableAnimation fullWidth 
                    className="sidebar-button" startContent={<FiLogOut />}
                    // @ts-ignore
                    onPress={() => window.location.href = "/logout"}>Logout</Button> 
                <div className="sidebar-category">Theme Switch</div>
                <div className="p-4 pl-6 pb-10">
                    <ThemeSwitch />
                </div>
            </div>
        )
    }

    return (
        <Popover>
            <PopoverTrigger>
                <Button 
                    startContent={<Image alt="avatar" src={userData.type === "password" ? "/icon_avatar.png" : userData.avatar} />} 
                    className="sidebar-profile mb-[64px]">{userData.name}</Button>
            </PopoverTrigger>
            <PopoverContent className="pb-4">
                <Button variant="flat" disableAnimation fullWidth 
                    className="sidebar-button" startContent={<FiLogOut />}
                    // @ts-ignore
                    onPress={() => window.location.href = "/logout"}>Logout</Button> 
                <div className="sidebar-category">Theme Switch</div>
                <ThemeSwitch />
            </PopoverContent>
        </Popover>
    )
}

export function SidebarMain(props: any) {
    const { safePush } = useSafePush();
    const { resolvedTheme: theme } = useTheme();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [ notifications, setNotifications ] = useState<Notification[]>([]);
        
    const [menuData, setMenuData] = useState<SidebarMenuEntry[]>([]);
    const router = useRouter(); 

    // const activeHref = !props.activeHref ? window.location.pathname : props.activeHref;

    useEffect(() => {
        setMenuData(GetMenuItems(router.query));
    }, [router]);

    useEffect(() => {
        notificationStorage.setHandler = setNotifications;
        notificationStorage.notifications = notifications;
    }, [notifications])
    
    if (props.shouldDisplay !== true) {
        return <></>
    };

    // const menuItems = GetMenuItems(window.location.pathname);
    let pageTitle = "";

    const entries = menuData.map((item: SidebarMenuEntry) => {
        if (!item.icon || !item.href) {
            return (
                <div key={item.label} className="sidebar-category">{item.label}</div>
            )
        }

        const isActive = window.location.pathname === item.href ? true : false;

        if (isActive) {
            pageTitle = item.label;
        }

        return (
            <Button key={item.href} variant="flat" disableAnimation fullWidth 
                className={ isActive ? "sidebar-button-current" : "sidebar-button"} disabled={isActive} startContent={item.icon}
                // @ts-ignore
                onPress={() => {setMenuOpen(false); safePush(item.href)}}>{item.label}</Button> 
        )
    })

    const sidebarTheme = theme === "dark" ? "dark" : "light";

    return (
        <>
            <div style={{ position: "absolute", width: "100%" }} className="md:hidden">
                <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={setMenuOpen} isBlurred={false}>
                    <NavbarContent>
                        <NavbarMenuToggle
                            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                        />
                        <NavbarBrand>
                            <p className="font-bold text-inherit">{pageTitle}</p>
                        </NavbarBrand>
                        <div className="text-foreground">
                            <NotificationsComponent notifications={notifications} setNotifications={setNotifications} />
                        </div>
                    </NavbarContent>
                    <NavbarMenu id="navbar" className={"navbar-"+sidebarTheme}>
                        {entries}
                        <ProfileDropdown type="mobile" />
                    </NavbarMenu>
                </Navbar>
            </div>
            {/* <div className={"z-40 w-24 2xl:w-32 min-h-full hidden xl:flex sidebar-left-"+sidebarTheme}></div> */}
            <div id="sidebar" className={"z-50 w-48 gap-2 min-h-full hidden flex-col md:flex lg:w-64 justify-start sidebar-"+sidebarTheme}>
                <div className="flex flex-col w-full">
                    <div className="pl-4 text-[#FFFFFF]">
                        <NotificationsComponent notifications={notifications} setNotifications={setNotifications} />
                    </div>
                    <ProfileDropdown />
                    {entries}
                </div>
            </div>
        </>
    )
}
